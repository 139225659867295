<template>
  <div v-if="!editPage">
    <settings-read-only @edit-page="switchPage"></settings-read-only>
  </div>
  <div v-if="editPage">
    <settings  @edit-page="switchPage"></settings>
  </div>

</template>
<script>

import SettingsReadOnly from "@/components/SettingsReadOnly.vue";
import Settings from "@/components/Settings.vue";


export default {
  components: {
    Settings,
    SettingsReadOnly
  },
  data(){
    return {
      editPage: false,
      page: this.$router.currentRoute._value.query.preferences ?? 'practice_details',
      tabActive: [true, false]
    }
  }, methods: {
    switchPage(){
      this.editPage = !this.editPage;
    },
  }
}
</script>