<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h1 class="h5 mb-0">Practice Preferences</h1>
    </div>
  </div>
  <Flash v-if="showFlash" alert="success">Your practice settings have been saved</Flash>
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="mb-2">
          <DropdownInput
              label="Receive daily summary email?"
              v-model="is_daily_summary"
              :model-value="is_daily_summary"
              :options="statuses"
              prompt="false"
              v=""
          />
        </div>
      </div>
      <div class="row">
        <div class="mb-2">
          <DropdownInput
              label="Hold scanner orders until reviewed?"
              v-model="is_holding_scanner_orders"
              :model-value="is_holding_scanner_orders"
              :options="statuses"
              v=""
              prompt="false"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="buttons button-div">
    <button class="btn btn-primary me-1" type="button" @click="submitButton">Save</button>
    <button class="btn btn-secondary me-1" type="button" @click="changePage">Cancel</button>
  </div>
</template>
<script>
import api from "@/services/api";
import Flash from "@/components/Flash.vue";
import DropdownInput from "@/components/DropdownInput.vue";

export default {
  components: {
    DropdownInput,
    Flash,
  },
  data() {
    return {
      address : {
        building: null,
        address: null,
        locality: null,
        town: null,
        postcode: null,
      },
      invoice_address: {
        company_name: null,
        building: null,
        address: null,
        locality: null,
        town: null,
        postcode: null,
      },
      isAddressSame: true,
      original_is_holding_scanner_orders: 0,
      is_holding_scanner_orders_changed: false,
      is_daily_summary: true,
      is_holding_scanner_orders: true,
      is_invoice_address: true,
      showFlash: false,
      statuses:[{id:true, label:'Yes'}, {id:false, label:'No'}],
    }
  },
  emits: ['update:is_invoice_address', 'onChange'],
  methods: {
    checkAddressesMatch(address,invoice_address){
      var addressKeys = Object.keys(address);

      for(var i =0; i<addressKeys.length; i++){
        if(address[addressKeys[i]] !== invoice_address[addressKeys[i]]){
          return false;
        }
      }
      return true;
    },
    fetchPracticeSettings: function () {
      api
          .get('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', {
          })
          .then((response) => {
            this.address = response.data.data.address;
            this.invoice_address = response.data.data.invoice_address;
            this.is_daily_summary = Boolean(response.data.data.is_daily_summary);
            this.original_is_holding_scanner_orders = response.data.data.is_holding_scanner_orders;
            if(this.original_is_holding_scanner_orders <= 1) {
              this.is_holding_scanner_orders = Boolean(response.data.data.is_holding_scanner_orders);
            } else {
              this.is_holding_scanner_orders = false;
            }
            this.is_invoice_address = Boolean(response.data.data.is_invoice_address);
            this.isAddressSame = this.checkAddressesMatch(this.address, this.invoice_address);
          })
          .catch((reason) => {
            console.log('Catch practice preferences error');
            console.log(reason);
          });
    },
    submitButton(){
      if(this.parseBool(this.is_invoice_address)){
        this.invoice_address = this.address;
      }
      var data = {
        'address': this.address,
        'invoice_address': this.invoice_address,
        'is_holding_scanner_orders': this.parseBool(this.is_holding_scanner_orders),
        'is_daily_summary': this.parseBool(this.is_daily_summary),
        'is_invoice_address': this.parseBool(this.is_invoice_address),
      };

      api
          .put('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', data)
          .then(() => {
            this.showFlash = true;
            this.$emit('editPage', false);
            window.location = window.location + '#flash';
          });
    },
    parseBool(string){
      if(typeof(string) === 'boolean'){
        return string;
      }

      return string === 'true'
    },
    changePage(){
      this.$emit('editPage', true);
    },
  },
  mounted() {
    this.fetchPracticeSettings();
  }
}
</script>