<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h1 class="h4 mb-0">Settings</h1>
    </div>
  </div>
  <div class="row">
    <SubsectionLink :to="{ name: 'SettingsPracticeDetails'}" heading="Address" description="View and update your practice invoice and delivery address" />
    <SubsectionLink :to="{ name: 'SettingsPracticePreferences'}" heading="Preferences" description="View and update your practice preferences" />
  </div>
</template>
<script>

import SubsectionLink from "@/components/SubsectionLink.vue";


export default {
  components: {
    SubsectionLink,
  }
}

</script>