<template>
    <div v-if="accountData">
        <div class="bg-white mb-3">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h1 class="h4 mb-0">{{ accountData.code }} - {{accountData.dentist.first_name}} {{accountData.dentist.surname}} at {{accountData.practice.company_name}}</h1>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link :to="{ name: 'Account', params: { account_id: accountData.id }}" class="nav-link active">Transactions</router-link>
            </li>
            <li class="nav-item">
                <router-link :to="{ name: 'Statements', params: { account_id: accountData.id }}" class="nav-link">Statements</router-link>
            </li>
            <li v-if="accountExports" class="nav-item">
                <router-link :to="{ name: 'AccountExports', params: { account_id: accountData.id }}" class="nav-link">Exports</router-link>
            </li>
        </ul>
        <div class="bg-white pt-4">
            <form class="bg-light p-3" novalidate @submit.prevent="submitFilter">
                <div class="row mb-3">
                    <div class="col-md-3">
                        <TextInput
                            label="Transaction ID"
                            v-model="transaction_id"
                            :v="v$.transaction_id"
                        />
                    </div>
                    <div class="col-md-3">
                        <DropdownInput
                            label="Transaction type"
                            v-model="transaction_type"
                            :v="v$.transaction_type"
                            :options="types"
                        />
                    </div>
                    <div class="col-md-3">
                        <DateInput
                            label="From date"
                            v-model="transaction_date_from"
                            :v="v$.transaction_date_from"
                        />
                    </div>
                    <div class="col-md-3">
                        <DateInput
                            label="To date"
                            v-model="transaction_date_to"
                            :v="v$.transaction_date_to"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <button class="btn btn-primary me-1" type="submit">Submit</button>
                        <span v-if="accountExports && exportable && transactionsData.data.length <= 1000 && transactionsData.data.length > 10 && transaction_type==1" id="export_all_button" class="btn btn-primary" @click="createExportBulk">
                            Export All                    
                        </span>
                    </div>
                </div>
            </form>
            <br />
            <table v-if="transactionsData" class="transaction-table">
                <thead>
                    <tr>
                        <th class="column-shrink">ID</th>
                        <th class="column-shrink">Date</th>
                        <th class="column-shrink">Type</th>
                        <th>Description</th>
                        <th class="column-shrink">Total</th>
                        <th class="column-shrink"></th>
                    </tr>
                </thead>
                <tbody v-for="(transaction, transactionIndex) of transactionsData.data" v-bind:key="transactionIndex" class="mb-1">
                    <tr>
                        <td>
                            {{transaction.id}}
                        </td>
                        <td>
                            {{this.$filters.formatDate(transaction.listed_date)}}
                        </td>
                        <td>
                            {{transaction.type.description}}
                        </td>
                        <td>
                            {{transaction.patient_name}}
                        </td>
                        <td class="column-number">
                            &pound;{{transaction.total}}
                        </td>
                        <td class="column-number">
                             <router-link class="btn btn-secondary" :to="{ name: 'Transaction', params: { account_id: accountData.id, id: transaction.id }}">View</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="transactionsData && !transactionsData.data.length">
                No Transactions
            </div>
            <Pagination
                v-if="transactionsData && transactionsData.data.length"
                v-bind:currentPage="currentPage"
                v-bind:lastPage="lastPage"
                viewComponent="Account"
            />
        </div>      
    </div>
</template>

<style scoped lang="scss">
    .transaction-table
    {
        border-collapse: collapse;
        width: 100%;
        line-height: 1em;

        &.product-table-collapsed
        {
            width: auto;
        }
        
        td
        {
            border-top: 1px solid #EEEEEE;
        }

        td,
        th
        {
            vertical-align: middle;
            padding: 10px 0.75em;

            &:first-child
            {
                padding-left: 0px;
            }

            &:last-child
            {
                padding-right: 0px;
            }
        }

        .column-shrink
        {
            width: 0px;
            white-space: nowrap;
        }

        .column-number
        {
            width: 0px;
            white-space: nowrap;
        }

        td.column-number
        {
            text-align: right;
        }
    }

    .hover-tooltip {
        width: 30%; 
        position: absolute;  
        background: black; 
        color: lightgrey; 
        z-index: 10; 
        border-radius: 5px;
        opacity: 0.8;
    }
</style>

<script>
    import api from "@/services/api";
    import useVuelidate from '@vuelidate/core'
    import Pagination from '@/components/Pagination.vue';
    import TextInput from '@/components/TextInput.vue';
    import DateInput from '@/components/DateInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            id: {
                required: true
            }
        },
        components: {            
            Pagination,
            DropdownInput,
            TextInput,
            DateInput,
        },
        validations () {
            return {
                transaction_id: { },
                transaction_type: { },
                transaction_date_from: { },
                transaction_date_to: { },
            }
        },
        data() {
            return {
                types: [],
                accountData: null,
                transactionsData: null,
                transaction_id: '',
                transaction_type: '',
                transaction_date_from: '',
                transaction_date_to: '',
                exportable: false,
                transaction_ids: null,
            };
        },
        mounted () {
            this.fetchAccount();
            this.fetchTypes();
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.transactionsData !== null)
                {
                    return parseInt(this.transactionsData.meta.last_page);
                }

                return null;
            },
        },
        watch: {
            currentPage() {
                this.fetchTransactions();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchTransactions();
            }
        },
        methods: {
            fetchAccount: function() {                
                api
                .get('/api/v1/accounts/' + this.id)
                .then((response) => {
                    this.accountData = response.data.data;

                    this.fetchTransactions();
                })
                .catch(() => {
                    console.log('Catch account error');
                });
            },
            fetchTransactions: function() {  
                var params = {
                    page: this.currentPage,
                };

                if(this.transaction_type.trim().length)
                {
                    params.type = this.transaction_type;
                }

                if(this.transaction_id.trim().length)
                {
                    params.id = this.transaction_id;
                }

                if(this.transaction_date_from.trim().length)
                {
                    params.from = this.transaction_date_from;
                }

                if(this.transaction_date_to.trim().length)
                {
                    params.to = this.transaction_date_to;
                }

                api
                .get('/api/v1/accounts/' + this.id + '/transactions', {
                    params: params,
                })
                .then((response) => {
                    this.transactionsData = response.data;
                })
                .catch(() => {
                    console.log('Catch transaction error');
                });
            },
            fetchTypes: function() {                
                this.types = [{
                    id: 1,
                    label: 'Invoice',
                }, {
                    id: 4,
                    label: 'Credit',
                }, {
                    id: 3,
                    label: 'Receipt',
                }, {
                    id: 5,
                    label: 'Overpayment',
                }, {
                    id: 6,
                    label: 'Refund',
                }]
            },
            createExportBulk: function() {

                var params = {};

                if(this.transaction_type.trim().length)
                {
                    params.type = this.transaction_type;
                }

                if(this.transaction_id.trim().length)
                {
                    params.id = this.transaction_id;
                }

                if(this.transaction_date_from.trim().length)
                {
                    params.from = this.transaction_date_from;
                }

                if(this.transaction_date_to.trim().length)
                {
                    params.to = this.transaction_date_to;
                }

                params.account_id = this.id;

                params.export_type = 1; // invoice

                document.getElementById('export_all_button').removeAttribute('onclick');

                api
                .post('/api/v1/accounts/createBulkExport', {
                    ...params,
                })
                .then((response) => {
                    console.log(response);
                })
                .catch(() => {
                    console.log('Catch transaction error');
                });

            },
            submitFilter: async function()
            {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.exportable = true;
                    this.fetchTransactions();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }

                return false;
            },
            exportBulk: async function()
            {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.createExportBulk();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }

                return false;
            },
        },
    }

</script>
