<template>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
            <h1 class="h4 mb-0">Royal Mail return label</h1>
        </div>
    </div>
    <PageSection style="padding-top:15px;">
        <p>You can send your orders to Swift for free via Royal Mail.</p>
    <p>Simply generate a label below and take it to your <a href="https://www.postoffice.co.uk/branch-finder" target="_blank">local post office</a>  or <a href="https://www.royalmail.com/d8/parcel-post-boxes" target="_blank">parcel post box</a></p>
    </PageSection>

    <PageSection>
        <PageSectionHeading>Please note:</PageSectionHeading>
        <ul>
            <li>Labels should be used only once.</li>
            <li>Re-used labels could result in Royal Mail returning your items.</li>
            <li>All items should be adequately packaged to protect them from damage.</li>
            <li>Include all items in a single box where possible as each box requires its own label.</li>
        </ul>
    </PageSection>
 

        <button class="btn btn-primary shadow-sm" @click="fetchReturnsLabel">Create Returns Label</button>


</template>

<script>
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';
    import api from "@/services/api";

    export default {
        components: {            
            PageSection,
            PageSectionHeading,
        },
        computed: {
            
            currentPracticeName () {
                if(this.$store.state.currentPractice === null)
                {
                    return '';
                }
                return this.$store.state.currentPractice.company_name;
            },
        },
        data() {
            return {
                labelData: null,
            }
        },
        methods: {
            fetchReturnsLabel: function() {
               let newWindow = window.open();
                api
                .post('/api/v1/returnslabel', {
                        practice_id: this.$store.state.currentPractice.id,
                        user_id: this.$store.state.user.id,
                        format: 2,
                        speed: null,
                        weight: 500,
                        account_id: null,
                        outputType: 'base64',
                        source: 'practice'
                })
                .then(response => {
                    this.labelData = response.data;

                    let base64Data = response.data.base64Label;

                    // Remove the "data:application/pdf;base64," part if it's included in your base64 string
                    base64Data = base64Data.replace("data:application/pdf;base64,", "");

                    // Decode base64 string
                    let decodedData = atob(base64Data);

                    let uint8Array = new Uint8Array(decodedData.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        uint8Array[i] = decodedData.charCodeAt(i);
                    }

                    let blob = new Blob([uint8Array], { type: 'application/pdf' });
                    let url = URL.createObjectURL(blob);

                    // Open PDF in a new tab
                    newWindow.location = url;


                })
                .catch(() => {
                    console.log('Label creation error');
                });
            },
        },

    }
</script>
