<template>
  <div v-if="!editPage">
    <practice-prefences-read-only @edit-page="switchPage"></practice-prefences-read-only>
  </div>
  <div v-if="editPage">
    <practice-prefences  @edit-page="switchPage"></practice-prefences>
  </div>
</template>
<script>
import PracticePrefencesReadOnly from "@/components/PracticePrefencesReadOnly.vue";
import PracticePrefences from "@/components/PracticePrefences.vue";


export default {
  components: {
    PracticePrefences,
    PracticePrefencesReadOnly,
  },
  data(){
    return {
      editPage: false,
      page: this.$router.currentRoute._value.query.preferences ?? 'practice_details',
      tabActive: [true, false]
    }
  }, methods: {
    switchPage(){
      this.editPage = !this.editPage;
    },
  }
}
</script>