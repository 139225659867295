<template>
  <Flash v-if="showFlash" alert="success">Your practice settings have been saved</Flash>
  <div class="d-flex mb-3 row">   
    <div class="col-md-5 col-sm-12 mb-2">
          <h1 class="h5 mb-3 mt-3">Practice Address</h1>
          {{address.building}}<br v-if="address.building"/>
          {{address.address}}<br v-if="address.address"/>
          {{address.locality}}<br v-if="address.locality"/>
          {{address.town}}<br v-if="address.town"/>
          {{address.postcode}}<br v-if="address.postcode"/>
    </div>

    <div class="col-md-5 col-sm-12 mb-2" v-if="parseBool(is_invoice_address)">
          <h1 class="h5 mb-3 mt-3">Invoice Address</h1>  
          {{invoice_address.company_name}}<br v-if="invoice_address.company_name"/>
          {{invoice_address.building}}<br v-if="invoice_address.building"/>
          {{invoice_address.address}}<br v-if="invoice_address.address"/>
          {{invoice_address.locality}}<br v-if="invoice_address.locality"/>
          {{invoice_address.town}}<br v-if="invoice_address.town"/>
          {{invoice_address.postcode}}<br v-if="invoice_address.postcode"/>
    </div>

    <div class="buttons col-md-2 col-sm-12">
      <button class="btn btn-primary me-1 mb-3" type="button" @click="changePage">Edit</button>
    </div>
  </div>
</template>


<script>
import api from "@/services/api";
import Flash from "@/components/Flash.vue";

export default {
  components: {
    Flash,
  },
  data() {
    return {
      address : {
        building: null,
        address: null,
        locality: null,
        town: null,
        postcode: null,
      },
      invoice_address: {
        company_name: null,
        building: null,
        address: null,
        locality: null,
        town: null,
        postcode: null,
      },
      isAddressSame: true,
      original_is_daily_summary: 0,
      is_daily_summary: true,
      is_holding_scanner_orders: true,
      is_invoice_address: true,
      showFlash: false,
      statuses:[{id:true, label:'Yes'}, {id:false, label:'No'}],
    }
  },
  emits: ['update:is_invoice_address', 'onChange'],
  methods: {
    checkAddressesMatch(address,invoice_address){
      var addressKeys = Object.keys(address);

      for(var i =0; i<addressKeys.length; i++){
        if(address[addressKeys[i]] !== invoice_address[addressKeys[i]]){
          return false;
        }
      }
      return true;
    },
    fetchPracticeSettings: function () {
      api
          .get('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', {
          })
          .then((response) => {
            this.address = response.data.data.address;
            this.invoice_address = response.data.data.invoice_address;
            this.is_daily_summary = Boolean(response.data.data.is_daily_summary);
            this.original_is_holding_scanner_orders = response.data.data.is_holding_scanner_orders;
            if(this.original_is_holding_scanner_orders <= 1) {
              this.is_holding_scanner_orders = Boolean(response.data.data.is_holding_scanner_orders);
            } else {
              this.is_holding_scanner_orders = false;
            }
            this.is_invoice_address = Boolean(response.data.data.is_invoice_address);
            this.isAddressSame = this.checkAddressesMatch(this.address, this.invoice_address);
          })
          .catch((reason) => {
            console.log('Catch practice preferences error');
            console.log(reason);
          });
    },
    submitButton(){
      if(this.parseBool(this.is_invoice_address)){
        this.invoice_address = this.address;
      }
      var data = {
        'address': this.address,
        'invoice_address': this.invoice_address,
        'is_invoice_address': this.parseBool(this.is_invoice_address),
      };

      api
          .put('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', data)
          .then(() => {
            this.showFlash = true;
            window.location = window.location + '#flash';
          });
    },
    parseBool(string){
      if(typeof(string) === 'boolean'){
        return string;
      }

      return string === 'true'
    },
    changePage(){
      this.$emit('editPage', true);
    }
  },
  mounted() {
    this.fetchPracticeSettings();
  }
}
</script>
