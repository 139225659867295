<template>
    <Flash v-if="showFlash" alert="success">Your practice settings have been saved</Flash>
  <div class="d-flex row">
  <div class="col-md-5 col-sm-12 mb-2">
    <h1 class="h5 mb-3 mt-3">Practice Address</h1>
      <div class="row">
        <div class="col-xs-12 mb-2">
      <TextInput
          label="Building"
          v-model="address.building"
          :model-value="address.building"
          v=""
      />
      </div>
      <div class="col-xs-12 mb-2">
      <TextInput
          label="Address"
          v-model="address.address"
          :model-value="address.address"
          v=""
      />
      </div>
      <div class="col-xs-12 mb-2">
      <TextInput
          label="Locality"
          v-model="address.locality"
          :model-value="address.locality"
          v=""
      />
        </div>
        <div class="col-xs-12 mb-2">
      <TextInput
          label="Town"
          v-model="address.town"
          :model-value="address.town"
          v=""
      />
      </div>
      <div class="col-xs-12 mb-2">
      <TextInput
          label="Postcode"
          v-model="address.postcode"
          :model-value="address.postcode"
          v=""/>
        </div>
        <div class="col-xs-12 mb-2">
      <DropdownInput
            label="Set different Invoice address"
            v-model="is_invoice_address"
            :model-value="is_invoice_address"
            :options="statuses"
            prompt="false"
            v=""
        />
        </div>
      </div>
      </div>
      <div class="col-md-2 hidden-sm"></div>
  <div class="col-md-5 col-sm-12" v-if="parseBool(is_invoice_address)">
        <h1 class="h5 mb-3 mt-3">Invoice Address</h1>
      <div class="row">
        <div class="col-xs-12 mb-2">
        <TextInput
            label="Company Name"
            v-model="invoice_address.company_name"
            :model-value="invoice_address.company_name"
            v=""
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 mb-2">
        <TextInput
            label="Building"
            v-model="invoice_address.building"
            :model-value="invoice_address.building"
            v=""
        />
      </div>
    </div>
    <div class="row">
      <div class="mb-2">
        <TextInput
            label="Address"
            v-model="invoice_address.address"
            :model-value="invoice_address.address"
            v=""
        />
      </div>
    </div>
    <div class="row">
      <div class="mb-2">
        <TextInput
            label="Locality"
            v-model="invoice_address.locality"
            :model-value="invoice_address.locality"
            v=""
        />
      </div>
    </div>
    <div class="row">
      <div class="mb-2">
        <TextInput
            label="Town"
            v-model="invoice_address.town"
            :model-value="invoice_address.town"
            v=""
        />
      </div>
    </div>
    <div class="row">
      <div class="mb-2">
        <TextInput
            label="Postcode"
            v-model="invoice_address.postcode"
            :model-value="invoice_address.postcode"
            v=""/>
      </div>
    </div>
  </div>
</div>


  <div class="buttons button-div">
    <button class="btn btn-primary me-1" type="button" @click="submitButton">Save</button>
    <button class="btn btn-secondary me-1" type="button" @click="changePage">Cancel</button>
  </div>

</template>

<style scoped lang="scss">
    .button-div{
      padding-top:15px;
    }
    .divider{
      margin-top:15px;
      border-top: 3px solid $swift-lighter-grey;
      margin-left: 12px;
      width: 98%;
    }
</style>

<script>
    import api from "@/services/api";
    import TextInput from "@/components/TextInput.vue";
    import Flash from "@/components/Flash.vue";
    import DropdownInput from "@/components/DropdownInput.vue";

    export default {
        components: {
          DropdownInput,
          Flash,
     TextInput,
        },
        data() {
          return {
            address : {
              building: null,
              address: null,
              locality: null,
              town: null,
              postcode: null,
            },
            invoice_address: {
              company_name: null,
              building: null,
              address: null,
              locality: null,
              town: null,
              postcode: null,
            },
            isAddressSame: true,
            original_is_daily_summary: 0,
            is_daily_summary: true,
            is_holding_scanner_orders: true,
            is_invoice_address: true,
            showFlash: false,
            statuses:[{id:true, label:'Yes'}, {id:false, label:'No'}],
          }
        },
        emits: ['update:is_invoice_address', 'onChange'],
        methods: {
          checkAddressesMatch(address,invoice_address){
            var addressKeys = Object.keys(address);

            for(var i =0; i<addressKeys.length; i++){
              if(address[addressKeys[i]] !== invoice_address[addressKeys[i]]){
                return false;
              }
            }
            return true;
          },
          fetchPracticeSettings: function () {
            api
                .get('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', {
                })
                .then((response) => {
                  this.address = response.data.data.address;
                  this.invoice_address = response.data.data.invoice_address;
                  this.original_is_daily_summary = response.data.data.is_daily_summary;
                  this.is_holding_scanner_orders = Boolean(response.data.data.is_holding_scanner_orders);
                  this.is_invoice_address = Boolean(response.data.data.is_invoice_address);
                  this.isAddressSame = this.checkAddressesMatch(this.address, this.invoice_address);
                })
                .catch((reason) => {
                  console.log('Catch practice preferences error');
                  console.log(reason);
                });
          },
          submitButton(){
            if(this.parseBool(this.is_invoice_address)){
              this.invoice_address = this.address;
            }
            var data = {
              'address': this.address,
              'invoice_address': this.invoice_address,
              'is_invoice_address': this.parseBool(this.is_invoice_address),
            };

            api
                .put('/api/v1/practices/' + this.$store.state.currentPracticeId+'/settings', data)
                .then(() => {
                  this.showFlash = true;
                  this.$emit('editPage', false);
                  window.location = window.location + '#flash';
                });
          },
          parseBool(string){
            if(typeof(string) === 'boolean'){
              return string;
            }

            return string === 'true'
          },
          changePage(){
            this.$emit('editPage', true);
          }
        },
        mounted() {
          this.fetchPracticeSettings();
        }
    }
</script>
