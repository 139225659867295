<template>
    <div id="close">
        <a href="javascript:window.close()" class="btn btn-secondary">Close</a>
    </div>
    <iframe id="iframe" v-if="dataLoaded" :src="iframeSrc" width="100%"></iframe>
</template>

<style>
    #close {
        height:65px; 
        padding:10px; 
        text-align:right;
    }
    #iframe {
        border: none;
        height: calc(100vh - 65px);
    }

</style>
<script>
import api from "@/services/api";
    export default {
        data() {
            return {
                iframeSrc: '',
                dataLoaded: false,
            }
        },
        methods: {
              fetchLink: function() {
                var apiRequests = [
                      api.get(`/api/v1/aligners/${this.$route.query.ref}/treatment-plan-url`)
                  ];
                  Promise.all(apiRequests).then((responses) => {
                    this.iframeSrc = decodeURIComponent(responses[0].data.data.url);
                    this.dataLoaded = true;
                  })
                  .catch((error) => {
                      console.error('An error occurred:', error);
                  });   
              }
          },
          mounted () {
              this.fetchLink();
          },
    }
</script>