<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h1 class="h4 mb-0 page-heading">Vouchers</h1>
    </div>
  </div>

    <PageSection>
        <div :key="index" v-for="(account, index, cronologicalIndex) in vouchers">
          <div>
            <h4 class="h5">{{account.account_name}}</h4>
            <div class="quick-link" :key="index2" v-for="(voucher,index2) in account.vouchers">
              <div class="icon-column">
                <div class="icon">
                  <i class="bi bi-ticket-detailed"></i>
                </div>
              </div>
              <div>
              <h6>{{voucher.effect_name}} <span v-if="voucher.quantity > 1">(x{{voucher.quantity}})</span> <i class="bi-question-circle tool-tip" @click="toggleTooltip(index,index2)" ></i> </h6>
              <p>This voucher can only be applied once per order.</p>
                <div class="tool-tip-ul" v-if=" checkToolTipState(index,index2)">
                  <p style="font-weight: bold" >This voucher applies to the following products</p>
                  <ul>
                    <li class="tool-tip-li" v-for="(product,index3) in voucher.products_effect_applies_to" :key="index3">{{product.name}}</li>
                  </ul>
                </div>
                </div>
            </div>
            <div v-if="Object.keys(vouchers).length > 1 && Object.keys(vouchers).length-1 !== cronologicalIndex " style="border-bottom: 2px solid #EEEEEE;"></div>
          </div>

          <br v-if="account.vouchers.length > 0" />
        </div>

    </PageSection>

</template>

<style scoped lang="scss">
.action
{
  padding-bottom: 20px;

  .action-link
  {
    display: flex;
    align-items: center;
  }

  .action-icon
  {
    aspect-ratio: 1/1;
    line-height: 0;
    font-size: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 70px;
  }
}

.voucher-section{
  display: flex;
  flex-direction: column;
}

.quick-link
{
  text-decoration: none;
  display: flex;
  padding-top:5px;
  padding-bottom:5px;

  .icon-column
  {
    display: flex;
    flex-direction: column;
    margin-right: $spacer;
  }

  .icon
  {
    background: $swift-lighter-grey;
    border-radius: 10px;
    aspect-ratio: 1/1;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .text
  {
    flex-grow: 1;
    margin-right: $spacer;
  }

  .text-description
  {
    line-height: 1.3em;
  }

  .arrow
  {
    img
    {
      width: 30px;
      margin-top: 10px;
    }
  }
}
.tool-tip-ul{
  border-radius: 10px;

  padding-top:5px;
  padding-bottom:5px;
  padding-right:5px;
}

.page-section
{
  margin-bottom: 0px;
  border-bottom: 0px solid $swift-lighter-grey;
}

</style>

<script>
    import PageSection from '@/components/PageSection.vue';
    import api from "@/services/api";
    import account from "./Account.vue";

    export default {
      computed: {
        account() {
          return account
        }
      },
        components: {
            PageSection,
        },
      data() {
        return {
          voucherSection: {
            voucher: null,
          },
          vouchers:null,
          accountSection: {
            account_id: null,
          },
          productsSection: {
            products: [],
          },
          tooltipPosition:[],
        }
      },
      mounted() {
          this.fetchAccounts();
          this.fetchVouchers();
      },
      methods: {
        fullName: function (account) {
          return account.dentist.first_name + ' ' + account.dentist.surname + ' (' + account.code + ')';
        },
        fetchAccounts: function() {
          api
              .get('/api/v1/accounts', {
                params: {
                  practice_id: this.$store.state.currentPracticeId,
                  bookingOnCheck: true,
                }
              })
              .then((response) => {
                this.accounts = response.data.data.map((account) => { return {
                  id: account.id,
                  label: this.fullName(account),
                }});

                if (this.accounts.length < 1)
                {
                  alert(
                      'There are no accounts that can create an order' +
                      '\n' +
                      'If you think this is a mistake please contact us on 01204 323 323'
                  );
                }
              })
              .catch(() => {
                console.log('Catch accounts error');
              });
        },
        fetchVouchers: function() {
          this.vouchers = [];
          //for some reason this.$store.state.user is the user ID sometimes :/
          // other times is a user Object :/
          let userId = this.$store.state.user;
          if(isNaN(this.$store.state.user)){
            userId = this.$store.state.user.id;
          }
            api
                .get('/api/v1/vouchersByPractice', {
                  params: {
                    practice_id: this.$store.state.currentPracticeId,
                    user_id: userId,
                  }
                })
                .then((response) => {
                  this.vouchers = response.data;
                  delete this.vouchers.voucherCount;
                  for(var i = 0;i<Object.keys(this.vouchers).length;i++){
                    for(var k =0; k < this.vouchers[i].vouchers.length; k++) {
                      //Vue hates 2D arrays which is the simplist way to impliment a vector
                      //So this hack is probably the easiest way to do it
                      //I'm also really not a fan of implimenting this way because it's clearly wrong
                      // I is account position, K is Voucher position
                      this.tooltipPosition[i+':'+k] = false;
                    }
                  }
                })
                .catch(() => {
                  console.log('Catch voucher error');
                });
        },
        toggleTooltip: function (index,index2){
          this.tooltipPosition[index+':'+index2] = !this.tooltipPosition[index+':'+index2]
        },
        checkToolTipState: function (index,index2){
           return this.tooltipPosition[index+':'+index2];
        },
      }
    }
</script>
