<template>
    <PageHeading>Successful sign in attempts</PageHeading>
    <ul v-if="successfulLoginsData && successfulLoginsData.data.length" class="successful-logins-list mb-4">
        <li>
            <div class="row align-items-center">
                <div class="col">
                    <div class=""><strong>Date / time</strong></div>
                </div>
                <div class="col">
                    <div class=""><strong>IP address</strong></div>
                </div>
            </div>
        </li>
        <li v-for="successfulLogin of successfulLoginsData.data" v-bind:key="successfulLogin.id">
            <div class="row align-items-center">
                <div class="col">
                    <div class="">{{ this.$filters.formatDateTime(successfulLogin.attempt_at) }}</div>
                </div>
                <div class="col">
                    <div class="">{{ successfulLogin.ip_address }}</div>
                </div>
            </div>
        </li>
    </ul>
    <div v-if="successfulLoginsData && !successfulLoginsData.data.length">
        No successful sign in attempts found.
    </div>
    <Pagination
        v-if="successfulLoginsData && successfulLoginsData.data.length"
        v-bind:currentPage="currentPage"
        v-bind:lastPage="lastPage"
        viewComponent="Security"
    />
</template>


<style scoped lang="scss">
    .successful-logins-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }
    }
</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';
    import PageHeading from '@/components/PageHeading.vue';

    export default {
        components: {            
            Pagination,
            PageHeading,
        },
        data() {
            return {
                successfulLoginsData: null,
            }
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.successfulLoginsData !== null)
                {
                    return parseInt(this.successfulLoginsData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            fetchSuccessfulLogins: function() {
                api
                .get('/api/v1/users/successfulLogins', {
                    params: {
                        page: this.currentPage,
                    }
                })
                .then((response) => {
                    this.successfulLoginsData = response.data;
                })
                .catch(() => {
                    console.log('Retrieving log-ins failed');
                });
                if(!this.$store.state.user)
                {
                    this.successfulLoginsData = null;
                    return;
                }

                this.moveUp();
                
            },
        },
        mounted () {
            this.fetchSuccessfulLogins();
        },
        watch: {
            currentPage() {
                this.fetchSuccessfulLogins();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchSuccessfulLogins();
            }
        },
    }
</script>