<template>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
            <h1 class="h4 mb-0 page-heading"><slot></slot></h1>
            <!-- <div>A list of orders that have been accepted</div> -->
        </div>
        <div>
            <router-link class="btn btn-primary" to="/orders/create">Create Order</router-link>
        </div>
    </div>
</template>

<script>
    export default { }
</script>

<style scoped lang="scss">
    .page-heading
    {
        letter-spacing: -0.05rem;
    }
</style>

