<template>
  <div id="flash" :class="currentClass" >

    <span v-if="(bold === 'true')" class="h5 page-section-heading" >
      <slot></slot>
    </span>

    <span v-if="(bold === 'false')" style="font-weight: normal" >
      <slot></slot>
    </span>


  </div>
</template>
<script>


export default {
  components: {
  },
  props: {
    alert: {
      default:'',
    },
    bold: {
      default: true,
    },
  },
  data() {
    return {
      currentClass : 'alert ',
      alertType: '',
    }
  },
  mounted() {
    this.setFlash()
  },
  methods: {
    setFlash: function() {
        if(this.alert === 'success'){
          this.currentClass += 'alert-success';
        } else if(this.alert === 'warning') {
          this.currentClass += 'alert-warning';
        }

    },
  }
}

</script>
