<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h1 class="h4 mb-0 page-heading"><PageHeading>Help</PageHeading></h1>
    </div>
  </div>


  <PageSection>
    <PageSectionHeading>How do I track my order?</PageSectionHeading>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/i9nuNEE1uqk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <ol>
      <li>From the menu, choose the “Orders” option.</li>
      <li>Using the filters available, search for your order by name, service level etc.</li>
      <li> Select your order from the filtered list.</li>
      <li> From this page, you can see the status of your order in the lab and the full order details including products requested, invoice and patient statement.</li>
    </ol>
  </PageSection>

  <PageSection>
    <PageSectionHeading>How do I request a collection?</PageSectionHeading>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/8w1ITxJvMps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <ol>
       <li> From the menu, select the “Collections” option</li>
       <li> View your current collections, to confirm that a collection doesn’t already exist for the day you require.</li>
       <li> Press the “Request collection” button </li>
       <li> Enter the date and time window that you require for this collection.</li>
       <li> Your collection has now been request and will be booked in the next few minutes.</li>
    </ol>
    Please note, you can only request collections for the next 5 working days.
  </PageSection>

  <PageSection>
    <PageSectionHeading>How do I download a statement and view invoices or credits?</PageSectionHeading>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/3jHh1DEqe0I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <ol>
       <li> From the menu, select the “Billing” option.</li>
       <li> Select the account you wish to view from the list.</li>
       <li> From this page, you can find all your invoices and credits. Viewing a credit will also give you the option to download a PDF for your records.</li>
       <li> For statement, choose the “Statements” tab at the top and select the month you require a statement for.</li>
    </ol>
  </PageSection>

  <PageSection>
    <PageSectionHeading>How do I create an order with impressions?</PageSectionHeading>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/kDbJ4Sog1Yo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <ol>
      <li>From the menu, select the “Orders” option.</li>
      <li>At the top of the orders list, click on “Create order”.</li>
      <li>Choose the impression type “Physical”</li>
      <li>Choose the financial account that the order should be placed under.</li>
      <li>Enter your patient reference and required service level.</li>
      <li>Choose your products using the categories provided.</li>
      <li>Enter your additional order details such as shade, along with any photos and notations you can provide.</li>
      <li>Choose a delivery date for the order. Please note that you will not be able to choose a delivery date that does not provide adequate days for manufacture.</li>
      <li>Write the 9 digit code provided on a piece of paper and attach them to the lab bag containing your impressions.</li>
      <li>Send the order to Swift Dental by driver collection, DPD collection or by post and we’ll do the rest!</li>
    </ol>
  </PageSection>

  <PageSection>
    <PageSectionHeading>Can I see my Trios and iTero orders in Swift Connect?</PageSectionHeading>
    Swift Dental have worked in partnership with both iTero and 3Shape to integrate our systems with both platforms. This not only means that orders from these platforms are dealt with quicker in the lab, but you can also see these orders in Swift Connect within minutes of you sending them through the scanner.
    The first time you send an order via either platform, we will match up your iTero or Trios account with your Swift Dental practice and dentist records. From this point on, you will see these orders in the “Requested” section of orders.
  </PageSection>

  <PageSection>
    <PageSectionHeading>Can I add additional details to Trios and iTero orders after sending them?</PageSectionHeading>
    Yes – Until your order has been accepted into the laboratory you will be able to add and amend the order details.
    Unfortunately, neither platform has a defined field for providing a service level (NHS/Independent/Private) when sending the scan, but this information can be added once the order appears in Swift Connect.
    Additionally, you will be able to choose a delivery date that takes our turnaround times into consideration, giving you confidence that it is possible for us to complete your order before the return date you provide.
  </PageSection>

  <PageSection isLastSection="true">
    <PageSectionHeading>Contact us</PageSectionHeading>
    <p>You can contact us by phone on 01204 323 323 or by email at <a href="mailto:info@swiftdental.co.uk">info@swiftdental.co.uk</a> and we will be happy to assist you.</p>
  </PageSection>
</template>

<style scoped lang="scss">
    
</style>

<script>
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';

    export default {
        components: {
            PageSection,
            PageSectionHeading,
        },
    }
</script>